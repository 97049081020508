<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <p class="description">
          この画面ではお店の外観や内覧写真などを掲載できます。<br />
          はじめて写真を登録される場合は、あらかじめカテゴリーを作成してください。
        </p>
      </section>
      <section class="section">
        <div class="row justify-content-center">
          <div class="col-6">
            <input
              class="form-control form-control-lg"
              :class="{ 'is-error': errors['categoryName'] || errors['name'] }"
              type="text"
              name="categoryName"
              v-trim
              v-maxlength
              maxlength="10"
              placeholder="カテゴリー名（10文字以内）"
              v-model="categoryName"
            />
          </div>
          <div class="col-auto">
            <ActionButton
              class="btn btn-lg btn-main"
              :handle-submit="addCategory"
              button-text="カテゴリーを追加"
            />
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>カテゴリー一覧</template>
              <template v-slot:headerContent
                ><Tooltip tooltipMsg="ドラッグ＆ドロップで並び替えできます"
              /></template>
              <template v-slot:body>
                <div class="scrollX">
                  <table class="dataTable sortable">
                    <thead>
                      <tr>
                        <th class="dataTable-fix"></th>
                        <th>カテゴリー名</th>
                        <th>写真数</th>
                        <th class="dataTable-fix"></th>
                      </tr>
                    </thead>
                    <Draggable
                      :list="categories"
                      @change="handleSort"
                      tag="tbody"
                      :animation="300"
                      handle=".sortable-handle"
                    >
                      <tr v-for="(item, index) in formData" :key="item.id">
                        <td>
                          <label class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :value="item.id"
                              v-model="checkedItems"
                              :disabled="item.count !== 0"
                            />
                            <span class="form-check-label"></span>
                          </label>
                        </td>
                        <td>
                          <input
                            class="form-control"
                            :class="{
                              'is-error': errors[`categoryName${index}`],
                            }"
                            type="text"
                            name="name"
                            v-model="item.name"
                            @blur="updateCategory(item.id, item.name, index)"
                            maxlength="10"
                            v-maxlength
                            v-trim
                          />
                        </td>
                        <td>{{ item.count }}</td>
                        <td>
                          <i class="aikon aikon-handle sortable-handle"></i>
                        </td>
                      </tr>
                      <tr v-if="isNoData" class="noData">
                        <td colspan="999">{{ noDataMessage }}</td>
                      </tr>
                    </Draggable>
                  </table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <Modal @close="closeModal()" v-if="modal">
        <template v-slot:headline>削除</template>
        <template v-slot:body>
          <p class="description text-align-center">
            選択したカテゴリーを削除しますか？
          </p>
          <ul class="listGrid justify-content-center">
            <li class="listGrid-item">
              <button
                class="btn btn-lg btn-white"
                type="button"
                @click="closeModal()"
              >
                キャンセル
              </button>
            </li>
            <li class="listGrid-item">
              <ActionButton
                class="btn btn-lg btn-red"
                :handle-submit="deleteCategory"
                button-text="削除する"
              />
            </li>
          </ul>
        </template>
      </Modal>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" :disabled="!isCheckedItemsExisted" type="button" @click="handleDeleted">
              カテゴリーを削除する
            </button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import Draggable from 'vuedraggable';
import nav from '@/mixins/nav/gallery';
import error from '@/mixins/plugin/error';
import modal from '@/mixins/plugin/modal';
import category from '@/mixins/module/category';
import { COMMON_MESSAGE } from '@/config/message';
//modal
import Tooltip from '@/components/Tooltip.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ShopGalleryCategory',
  data: function() {
    return {
      pageName: 'ギャラリー',
      categoryName: '',
      deletedModal: false,
      checkedItems: [],
      maxCategory: 20,
      formData: [],
    };
  },
  components: {
    Draggable,
    Tooltip,
    Modal,
  },
  mixins: [nav, error, modal, category],
  validations: {
    categoryName: { required, maxLength: maxLength(10) },
  },
  computed: {
    ...mapGetters({
      categories: 'gallery/categoryList',
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    isMaximumCategory() {
      return this.categories.length === this.maxCategory;
    },
    isNoData() {
      return this.categories.length === 0;
    },
    noDataMessage() {
      return COMMON_MESSAGE.noData;
    },
    isCheckedItemsExisted() {
      return this.checkedItems.length > 0;
    }
  },
  watch: {
    categories(nVal) {
      this.formData = nVal.map((el) => ({ ...el }));
    },
  },
  methods: {
    async handleSort(value) {
      this.handleSortCategory(value, 'ord', 'sortOrder', this.categories, 'gallery/sortCategories');
    },
    addCategory: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v, 'gallery');
      } else {
        this.errors = {};
        this.$message.reset();
        if (this.checkExistCategoryName(this.categoryName, this.categories)) {
          this.errors['categoryName'] = true;
          this.showErrorDuplicateName();
        } else if (this.isMaximumCategory) {
          this.$message.showError('maxCategory', 'max', this.maxCategory);
        } else {
          this.createCategory();
        }
      }
    },
    showErrorDuplicateName() {
      this.$message.showError('duplicateCategory');
    },
    async updateCategory(id, name, index) {
      if (name === this.categories[index].name) {
        this.getCategories();
        return;
      } else if (this.checkExistCategoryName(name, this.categories)) {
        this.$set(this.errors, `categoryName${index}`, true);
        this.showErrorDuplicateName();
      } else {
        this.handleUpdateCategory(id, name, index, this.categories, async() => {
        this.errors = {};
        await this.$store.dispatch('gallery/updateCategory', {
          subdomain: this.subdomain,
          shopId: this.shopId,
          id: id,
          data: { name: name },
        });
        this.getCategories();
        });
      }
    },
    getCategories() {
      return this.$store.dispatch('gallery/getCategories', {
        subdomain: this.subdomain,
        shopId: this.shopId,
      });
    },
    async createCategory() {
      const result = await this.$store.dispatch('gallery/createCategory', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        data: { name: this.categoryName },
      });
      if (result) {
        this.categoryName = '';
        this.getCategories();
        this.$message.created('category');
      }
    },
    async deleteCategory() {
      const result = await this.$store.dispatch('gallery/deleteCategory', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        data: { id: this.checkedItems },
      });
      this.checkedItems = [];
      this.closeModal();
      if (result) {
        this.getCategories();
        this.$message.deleted('category');
      }
    },
    handleDeleted() {
      if (this.isCheckedItemsExisted) {
        this.showModal();
      } else {
        this.$message.noSelectedItem();
      }
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.getCategories();
    this.$loading.clear(loading);
  },
};
</script>
