export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: 'カテゴリー一覧',
          path: '/shop/gallery/category',
          routeName: 'ShopGalleryCategory',
          includes: false,
        },
        {
          id: '02',
          name: '写真一覧',
          path: '/shop/gallery/list',
          routeName: 'ShopGalleryList',
          includes: false,
        },
        {
          id: '03',
          name: '写真登録',
          path: '/shop/gallery/register',
          routeName: 'ShopGalleryRegister',
          includes: false,
        },
      ],
    };
  },
};
